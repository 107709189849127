import { Injectable } from '@angular/core';
import { NotificationCenterEntry, NotificationCenterUpdate } from '@weavix/models/src/notification/notification-center';
import { Topic } from '@weavix/models/src/topic/topic';
import { HttpService } from 'weavix-shared/services/http.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { uniqBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { PushPayload } from '@weavix/models/src/push/push';

export interface FacilityAlertPersonUpdateRequest {
    read?: boolean;
    removed?: boolean;
}

export const openNotification$: BehaviorSubject<string | NotificationCenterEntry> = new BehaviorSubject(null);
export const closeNotification$: BehaviorSubject<number> = new BehaviorSubject(null);

export const updateNotificationIcon$: BehaviorSubject<any> = new BehaviorSubject(null);
export const closeNotificationDropdown$: BehaviorSubject<any> = new BehaviorSubject(null);

@Injectable({
    providedIn: 'root',
})
export class NotificationCenterService {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
    ) { }

    currentNotifications: NotificationCenterEntry[];

    static api = (id?: string) => id ? `/core/notification-center-entries/${id}` : `/core/me/notification-center-entries`;
    static facilityAlertApi = (id?: string, accountId?: string) => id ? `/a/${accountId}/track/facility-alerts/${id}` : `/a/${accountId}/track/facility-alerts`;

    async getById(component: any, id: string) {
        return this.httpService.get<NotificationCenterEntry>(component, NotificationCenterService.api(id));
    }

    async getAll(component: any) {
        const test = await this.httpService.get<NotificationCenterEntry[]>(component, NotificationCenterService.api());
        // handling a bug where alerts are duplicated with different account IDs.
        // Ticket for fix is in, but this should delete the duplicates if there are any
        const returning = uniqBy(test, m => m.id);
        this.currentNotifications = returning;
        return returning;
    }

    async update(component: any, id: string, notification: NotificationCenterUpdate) {
        return this.httpService.put(component, NotificationCenterService.api(id), notification);
    }

    async updateFacilityAlert(component: any, id: string, accountId: string, updateRequest: FacilityAlertPersonUpdateRequest) {
        return this.httpService.put(component, NotificationCenterService.facilityAlertApi(id, accountId), updateRequest);
    }

    subscribeNotificationCreated(component: any, userId: string) {
        return this.pubSubService.subscribe<NotificationCenterEntry>(component, Topic.UserNotificationCreated, [userId]);
    }

    subscribeNotification(component: any, userId: string) {
        return this.pubSubService.subscribe<PushPayload>(component, Topic.UserNotification, [userId], null, 0);
    }
}
