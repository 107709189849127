import { bootstrap } from './bootstrap';
bootstrap();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { AppModule } from './app/app.module';

import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/mode/sql/sql.js';

if (environment.production) {
  enableProdMode();
}

import Bugsnag from '@bugsnag/js';

Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    enabledReleaseStages: ['dev', 'stage', 'demo', 'canary', 'prod'],
    releaseStage: environment.releaseStage,
    appVersion: environment.version,
});

const consoleError = console.error;

console.error = (...args) => {
    const exc = args.find(x => x instanceof Error);
    if (exc) Bugsnag.notify(exc);
    consoleError.apply(console, args);
};

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/custom-service-worker.js');
    }
}).catch(err => console.error(err));
