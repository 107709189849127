import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { css } from '@weavix/domain/src/utils/css';
import { Avatar, AvatarPopoverTrigger } from '@weavix/models/src/avatar/avatar';
import { SvgIcon } from '@weavix/models/src/icon/icon';

import { Subscription } from 'rxjs';
import { PersonService } from 'weavix-shared/services/person.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

/* Indicators is a bit flag enum that specifies which indicators to show
   on the output of the avatar. */
export enum Indicators {
    None = 0,
    Availability = 1,
    Teams = 2,
    VideoCall = 4,
    VoiceCall = 8,
}

@AutoUnsubscribe()
@Component({
    selector: 'app-avatar-person',
    templateUrl: './avatar-person.component.html',
    styleUrls: ['./avatar-person.component.scss'],
})
export class AvatarPersonComponent implements OnInit, OnChanges, OnDestroy {

    @Input() personId: string;
    @Input() avatarInput: Avatar;
    @Input() showCompanyRing: boolean = true;
    @Input() backgroundColor: string;
    @Input() size: number;
    @Input() circular: boolean = true;
    @Input() showAvailability: boolean = true;
    @Input() indicators: Indicators = Indicators.Availability | Indicators.Teams;
    @Input() hasUserInfoPopover = false;

    @Output() avatarPopoverOutput: EventEmitter<AvatarPopoverTrigger> = new EventEmitter();

    private defaultAvatar: Avatar = {
        height: 50,
        width: 50,
        editable: false,
    };

    available: boolean;
    avatar: Avatar;
    svgIcon = SvgIcon;
    svgHeight: number = 0;
    svgWidth: number = 0;
    person: any;
    readonly Indicators = Indicators;
    availabilityIndicatorStyle: {
        'bottom.px': number;
        'right.px': number;
        'height.px': number;
        'width.px': number;
        'border-radius.px': number;
    };
    teamsIndicatorStyle: {
        'bottom.px': number;
        'left.px': number;
        'height.px': number;
        'width.px': number;
        'border-radius.px': number;
        'display': string;
    };
    teamsIconStyle: {
        'display': string;
    };
    callIndicatorStyle: {
        'bottom.px': number;
        'right.px': number;
        'height.px': number;
        'width.px': number;
    };
    videoCallIconStyle: {
        'display': 'contents';
    };

    private availabilitySubscription: Subscription;
    constructor(
        private personService: PersonService,
    ) {}

    async ngOnInit() { }

    ngOnDestroy() {
        this.availabilitySubscription?.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.personId || changes.avatarInput || changes.size) {
            this.availabilitySubscription?.unsubscribe();
            if (this.personId == null) {
                this.person = null;
                this.avatar = null;
                return;
            }

            try {
                this.avatar = { ...this.defaultAvatar, ...this.avatarInput };
                try {
                    this.person = this.personService.getUserFromStore(this.personId);
                } catch (e) {
                    this.person = await this.personService.getPerson(null, this.personId, true, true);
                }

                const company = this.person?.company;

                if (this.size) {
                    this.avatar.height = this.size;
                    this.avatar.width = this.size;
                }
                this.avatar.circular = this.circular;
                if (!this.avatar.img) {
                    this.avatar.img = this.person?.avatarFile;
                }
                if (!this.avatar.name) this.avatar.name = this.person?.fullName;
                if (!this.avatar.outlineColor) this.avatar.outlineColor = this.showCompanyRing ? company ? company.color : css.colors.WHITE_65P : null;

                if (this.person?.available$) {
                    this.availabilitySubscription = this.person.available$.subscribe(available => {
                        this.available = available;
                    });
                }

                if (this.showAvailability) {
                    if (this.indicators & Indicators.Availability) this.setAvailableIndicatorStyle();
                    if (this.indicators & Indicators.Teams) this.setTeamsIndicatorStyle();
                    if ((this.indicators & Indicators.VideoCall) || (this.indicators & Indicators.VoiceCall)) this.setCallIndicatorStyle();
                }
                this.avatar = { ...this.avatar };
            } catch (e) {
                console.error('Failed to load avatar person data', e);
            }
        }
    }

    private setAvailableIndicatorStyle() {
        const width = Math.min(this.avatar?.width / 3, 20);
        const height = Math.min(this.avatar?.height / 3, 20);

        this.availabilityIndicatorStyle = {
            'bottom.px': this.avatar?.height * 0.04,
            'right.px': this.avatar?.height * 0.04 - (width / 3),
            'height.px': height,
            'width.px': width,
            'border-radius.px': this.avatar?.width / 6,
        };
    }

    private setTeamsIndicatorStyle() {
        const width = Math.min(this.avatar?.width / 3, 20);
        const height = Math.min(this.avatar?.height / 3, 20);
        this.svgHeight = height;
        this.svgWidth = width;

        this.teamsIndicatorStyle = {
            'bottom.px': this.avatar?.height * 0.04,
            'left.px': this.avatar?.height * 0.04 - (width / 3),
            'height.px': height,
            'width.px': width,
            'border-radius.px': this.avatar?.width / 6,
            'display': 'flex',
        };

        this.teamsIconStyle = {
            'display': 'contents',
        };
    }

    private setCallIndicatorStyle(): void {
        const width = this.avatar?.width / 3 > 30 ? this.avatar?.width / 3 : 30;
        const height = this.avatar?.height / 3 > 30 ? this.avatar?.height / 3 : 30;
        this.svgHeight = height * 0.566;
        this.svgWidth = width * 0.566;

        this.callIndicatorStyle = {
            'bottom.px': this.avatar?.height * 0.023,
            'right.px': this.avatar?.width * 0.023,
            'height.px': height,
            'width.px': width,
        };
    }

    public hasIndicator(value: Indicators, indicator: Indicators): boolean {
        return (value & indicator) === indicator;
    }

    handleAvatarPopoverOutput(trigger: AvatarPopoverTrigger) {
        if (!this.hasUserInfoPopover) return;
        this.avatarPopoverOutput.emit({ ...trigger, personId: this.personId });
    }
}
