import { FrontendEnvironment } from '@weavix/domain/src/frontend-environment/frontend-environment';
import { getMicroblinkLicense } from './environment.microblink';
import { WeavixEnv } from './environment.model';
import { getTeamsAppId, isTeamsApp } from './environment.teams';

const frontendEnvironment = new FrontendEnvironment(window.location.hostname);
const domain = frontendEnvironment.domain;

export const environment: WeavixEnv = {
    production: frontendEnvironment.isProduction(),
    radioUrl: frontendEnvironment.getSiblingUrl('radio'),
    consoleUrl: frontendEnvironment.getSiblingUrl('console'),
    is360Api: frontendEnvironment.getSiblingUrl('api'),
    pubsubApi: frontendEnvironment.getSiblingUrl('mqtt').replace('https://', ''),
    pubsubPort: frontendEnvironment.getPubSubPort(),
    analyticsSource: 'is360-webapp',
    version: 'wn-1976.8', // replaced during gitlab build - see set-ng-environment-version.sh
    commit: '033fcb4aa1b4bb540d82f1d0f555c60f524a5e0f', // replaced during gitlab build - see set-ng-environment-version.sh
    releaseStage: frontendEnvironment.getReleaseStage(),
    enableRouteTracing: domain === 'localhost',
    enableReports: domain === 'localhost' || domain === 'weavixdev.com' || domain === 'weavixdemo.com',
    bugsnagApiKey: '35c3d21496296d09d13a27ca69b265f0',
    microblinkLicense: getMicroblinkLicense(domain),
    teamsApp: isTeamsApp(),
    teamsAppId: getTeamsAppId(domain),
    radioApp: window.location.host.startsWith('radio') || window.location.host.endsWith(':4201'),
    consoleApp: window.location.host.startsWith('console') || window.location.host.endsWith(':4202'),
    supportUrl: 'https://help.weavix.com',
};
