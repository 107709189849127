import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SyncEventStorage, SyncStorage } from './sync.model';

import { SyncPartition } from './sync.partition';
import { SyncService, UserAccount } from './sync.service';

export abstract class SyncModule<T extends { id?: string }> {
    static modules: SyncModule<any>[] = [];

    private partitions: { [topic: string]: SyncPartition<T> } = {};
    protected get syncService() {
        return SyncService.instance;
    }

    protected abstract storage: SyncStorage<T>;
    protected abstract eventStorage: SyncEventStorage;

    loading = 0;

    constructor(private lazy: boolean) {
        SyncModule.modules.push(this);
        this.start();
    }

    async close(reset: boolean) {
        await Promise.all(Object.keys(this.partitions).map(async removed => {
            await this.partitions[removed].close(reset);
            delete this.partitions[removed];
        }));
        if (reset) {
            const remaining = await this.eventStorage.getPartitions();
            await Promise.all(remaining.map(async (removed) => await this.eventStorage.clear(removed)));
        }
        this.addLoading(-this.loading);
    }

    async flush() {
        await Promise.all(Object.values(this.partitions).map(async partition => {
            await partition.flush();
        }));
    }

    protected watchUser(fn: (user: UserAccount | null) => string[] | string | null) {
        return SyncService.user$.pipe(map(fn));
    }

    // Usually userId or accountIds
    abstract watchPartitions(): Observable<string[] | string | null>;
    abstract getTopic(topic: string): string;

    private start() {
        this.watchPartitions().subscribe(async (partitionOrList) => {
            if (!partitionOrList) return; // On startup we might not have the right set of topics, let it get right

            this.addLoading(1);
            const partitions = Array.isArray(partitionOrList) ? partitionOrList : [partitionOrList];

            // If we started a partition, stop it
            Object.keys(this.partitions)
                .filter(x => !partitions.includes(x))
                .forEach(removed => {
                    this.partitions[removed].close(true);
                    delete this.partitions[removed];
                });

            // If we have a lingering partition from before startup, remove it
            const previousPartitions = await this.eventStorage.getPartitions();
            await Promise.all(
                previousPartitions
                    .filter(x => !partitions.includes(x))
                    .map(async (removed) => await this.eventStorage.clear(removed))
            );

            // New topic, add and start it
            partitions.filter(partition => !this.partitions[partition]).forEach(partition => this.addPartition(partition));
            this.addLoading(-1);
        }, error => {
            console.error(error);
        });
    }

    private addPartition(partition: string, reset = false) {
        let loaded = false;
        this.partitions[partition] = this.createSyncPartition(partition, reset);
        if (!reset) {
            this.addLoading(1);
            this.partitions[partition].loaded$.subscribe(() => {
                if (!loaded) this.addLoading(-1);
                loaded = true;
            });
        }
        this.partitions[partition].reset$.subscribe(() => {
            delete this.partitions[partition];
            this.addPartition(partition, true);
        });
    }

    private createSyncPartition(partition: string, reset = false) {
        const topic = this.getTopic(partition);
        return new SyncPartition(this.syncService, this.storage, this.eventStorage, topic, partition, this.lazy, reset);
    }

    private addLoading(change: number) {
        const subj = this.lazy ? this.syncService.lazyLoading$ : this.syncService.priorityLoading$;
        subj.next(subj.value + change);
        this.loading += change;
    }
}
