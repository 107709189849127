import { Injectable, Injector } from '@angular/core';
import {
    Call,
    CallAgent,
    CallClient,
    CallEndReason,
    DeviceManager,
    IncomingCall,
    LocalVideoStream,
    RemoteParticipant,
    TeamsCall,
    TeamsCallAgent,
    TeamsIncomingCall,
    VideoOptions,
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential, CommunicationIdentifier, CommunicationIdentifierKind, MicrosoftTeamsUserIdentifier, createIdentifierFromRawId } from '@azure/communication-common';
import { CommunicationAccessToken } from '@azure/communication-identity';
import { authentication, call as msCall } from '@microsoft/teams-js';
import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { StAction, StObject } from '@weavix/models/src/analytics/analytics';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { sleep } from 'weavix-shared/utils/sleep';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import * as moment from 'moment';
import { LoggedInUser, User } from 'weavix-shared/models/user.model';
import { AnalyticsService } from './analytics.service';
import { myUser, usersContext, User as MobxUser } from 'models-mobx/users-store/users-store';
import { ProfileService } from './profile.service';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { Channel } from 'models-mobx/channels-store/channel';
import { myUserId } from 'models-mobx/my-profile-store/my-profile-store';
import { channelsContext } from 'models-mobx/channels-store/channels-store';
import { ChannelService } from './channel.service';
import { NotificationCenterService } from './notification-center.service';
import { filter, map } from 'rxjs/operators';
import { PushTopic } from '@weavix/models/src/push/push';

const DEFAULT_WAIT_MS = 10000;

export interface CallRequest {
    channel: Channel;
    initiator: User;
    type: CallType;
}

export enum CallType {
    Video = 'video',
    Voice = 'voice',
}

export interface CameraState {
    isDisabled: boolean;
}

export interface MuteState {
    isMuted: boolean;
}

export interface VideoCallingAccessToken extends CommunicationAccessToken {
    type: 'acs' | 'teams';
}

export enum CallSystemMessageType {
    AnsweredCall = 'answered-call',
    CalledChannel = 'called-channel',
    DeclinedCall = 'declined-call',
    MissedCall = 'missed-call',
}

export interface CallingSystemMessageRequest {
    type: CallSystemMessageType;
    userId?: string;
}

export interface RequestAcsCallRequest {
    channelId?: string;
    userId?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AcsService {
    constructor(
        private httpService: HttpService,
        private alertService: AlertService,
        private profileService: ProfileService,
        private notificationCenterService: NotificationCenterService,
        private injector: Injector,
    ) { }

    private accessToken: VideoCallingAccessToken = null;
    private allowVideoCalls: boolean = false;
    private allowVoiceCalls: boolean = false;
    private callAgent: TeamsCallAgent | CallAgent = null;
    private callClient: CallClient = null;
    private currentCall: TeamsCall | Call = null;
    private deviceManager: DeviceManager = null;
    private localVideoStream: LocalVideoStream = null;
    private userAllowsVideo: boolean = false;
    private calledChannelId: string;

    private callType: CallType;
    private localMuteState: MuteState;
    private localCameraState: CameraState;
    private callConnectedTimestamp: number;
    private lastCallDuration: number;
    private tokenType: 'acs' | 'teams';

    callConnected$: Subject<TeamsCall | Call> = new Subject<TeamsCall>();
    callEnded$: Subject<CallEndReason> = new Subject<CallEndReason>();
    callIncoming$: Subject<TeamsIncomingCall | IncomingCall> = new Subject<TeamsIncomingCall | IncomingCall>();
    callRequested$: Subject<CallRequest> = new Subject<CallRequest>();
    callRinging$: Subject<void> = new Subject<void>();
    callStarted$: Subject<TeamsCall | Call> = new Subject<TeamsCall>();
    cameraStateChanged$: Subject<CameraState> = new Subject<CameraState>();
    muteStateChanged$: Subject<MuteState> = new Subject<MuteState>();
    remoteParticipantChanged$: Subject<RemoteParticipant[]> = new Subject<RemoteParticipant[]>();
    recording$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    transcribing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    userRequestAcsCallSubscription?: Subscription;

    get currentCalledChannelId() {
        return this.calledChannelId;
    }

    setCurrentCalledChannelId(value: string) {
        this.calledChannelId = value;
    }

    async getAccessToken(): Promise<VideoCallingAccessToken> {
        return this.httpService.get<VideoCallingAccessToken>(null, '/acs/access-token');
    }

    async getCurrentAccessToken(): Promise<VideoCallingAccessToken> {
        if (!this.accessToken || this.accessToken.expiresOn <= new Date()) {
            this.accessToken = await this.getAccessToken();
        }
        return this.accessToken;
    }

    getUserConsentUrl(userId: string, tenantId: string): Promise<string> {
        return this.httpService.get(null, `/teams/consent/user-url?userId=${userId}&tenantId=${tenantId}`);
    }

    async init(user: LoggedInUser, allowVideoCalls: boolean, allowVoiceCalls: boolean, force?: boolean): Promise<void> {
        this.allowVideoCalls = allowVideoCalls;
        this.allowVoiceCalls = allowVoiceCalls;
        let acsToken: VideoCallingAccessToken = null;
        if (environment.teamsApp) {
            if (!await this.httpService.get<boolean>(null, '/acs/has-ad-token')) {
                const testTokenRequest = {
                    successCallback: () => { },
                    failureCallback: (e) => {console.error('Failed to get auth token', e); },
                    url: `${window.location.origin}/teams/teams-authenticate?userId=${user.id}`,
                };
                await authentication.authenticate(testTokenRequest);
            }
            return;
        }

        // don't start ACS service if none of the user's accounts have video or voice permissions
        if (!this.profileService.hasAccountPermissionInAnyAccount(PermissionAction.VideoCalling)
            && !this.profileService.hasAccountPermissionInAnyAccount(PermissionAction.VoiceCalling)) return;

        while (!acsToken) {
            try {
                acsToken = await this.getCurrentAccessToken();
            } catch (e) {
                console.error(`Unable to get an access token: ${e}`);
                if (e.error?.message?.includes('Another object with the same value')) return;
            } finally {
                if (!acsToken) {
                    await sleep(DEFAULT_WAIT_MS);
                }
            }
        }

        if (!this.callClient || force) {
            this.callClient = new CallClient();
            const refreshToken = async(): Promise<string> => (await this.getAccessToken()).token;
            const token = new AzureCommunicationTokenCredential({ tokenRefresher: refreshToken, token: this.accessToken.token, refreshProactively: true });
            this.tokenType = this.accessToken.type;
            if (this.accessToken.type === 'teams') {
                this.callAgent = await this.callClient.createTeamsCallAgent(token);
                this.callAgent.on('incomingCall', async args => this.incomingCall(args.incomingCall));
            } else {
                this.callAgent = await this.callClient.createCallAgent(token, { displayName: myUser().fullName });
                this.callAgent.on('incomingCall', async args => this.incomingCall(args.incomingCall));
                this.subscribeToUserRequestAcsCall();
            }
            this.deviceManager = await this.callClient.getDeviceManager();

            const defaultMicrophone = await LocalDeviceUtility.getDefaultAudioInputDevice();
            this.changeMicrophone(defaultMicrophone.deviceId);

            const defaultSpeaker = await LocalDeviceUtility.getDefaultAudioOutputDevice();
            this.changeSpeaker(defaultSpeaker.deviceId);
        }
    }

    async onLogout() {
        this.userRequestAcsCallSubscription?.unsubscribe();
        this.userRequestAcsCallSubscription = null;
        if (this.isOnACall()) await this.endCall(true);
        await this.callAgent?.dispose();
        this.callAgent = null;
        this.callClient = null;
        this.accessToken = null;
        this.deviceManager = null;
    }

    subscribeToUserRequestAcsCall() {
        this.userRequestAcsCallSubscription?.unsubscribe();
        this.userRequestAcsCallSubscription = this.notificationCenterService.subscribeNotification(null, myUserId())
            .pipe(
                filter(x => x.payload.topic === PushTopic.RequestAcsCall),
                map(x => x.payload.data as RequestAcsCallRequest),
            )
            .subscribe(data => {
                console.log('Received request call payload', JSON.stringify(data));
            });
    }

    ready(): boolean {
        return this.accessToken && this.callAgent ? true : false;
    }

    isOnACall(): boolean { return !!this.currentCall; }

    // returns list of email addresses of other people in the channel that can be called using Team
    // meeting functionality.
    async getChannelRecipientsEmails(channelId: string) {
        return this.httpService.get<any>(null, `/core/channels/${channelId}/call-recipients-emails`);
    }

    // returns a list of Teams identity for other people in the channel.
    async getChannelRecipientsIds(channelId: string) {
        return this.httpService.get<any>(null, `/core/channels/${channelId}/call-recipients`);
    }

    async requestTeamsToAcsCall(channelId?: string, userId?: string) {
        const body: RequestAcsCallRequest = {
            channelId,
            userId,
        };
        return this.httpService.post<any>(null, '/acs/request-acs-call', body);
    }

    async requestOneToOneMeeting(personId: string) {
        return this.httpService.get<any>(null, `/teams/one-to-one-meeting/${personId}`);
    }

    async getUserIdentity(userId: string) {
        return this.httpService.get<any>(null, `/acs/identity/${userId}`);
    }

    async requestCall(channel: Channel, type: CallType, initiator: User) {
        this.callRequested$.next({ channel, type, initiator });
    }

    async createCallSystemMessage(channelId: string, messageType: CallSystemMessageType, userId?: string) {
        const body: CallingSystemMessageRequest = {
            type: messageType,
            userId,
        };
        return await this.httpService.post(null, `/core/channels/${channelId}/calling-system-messages`, body);
    }

    // Starts a video/voice call with one user. This call utilizes Teams library to start the call instead of ACS.
    // NOTE: weTeams allows teams -> acs calls!
    async startWeTeamsOneToOneCall(userId: string, callType: CallType) {
        try {
            if (!environment.teamsApp) await this.askPermissions(this.allowVideoCalls);
            const response = await this.getUserIdentity(userId);
            if (!response.length) this.alertService.sendError(null, 'ERRORS.CALLS.USER_UNAVAILABLE');
            else {
                this.callType = callType;
                const modalities = callType === CallType.Video ? [msCall.CallModalities.Video] : [msCall.CallModalities.Audio];
                const targets = [response];

                AnalyticsService.track(
                    this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
                    StAction.Started,
                    this.constructor.name,
                    {
                        object: {
                            channelMemberCount: targets?.length + 1,
                        },
                    },
                );

                msCall.startCall({ targets, requestedModalities: modalities });
                const defaultChannelsContext = channelsContext.getDefault();
                const existingChannel = defaultChannelsContext.getChannelByPersonIds([myUser().id, userId]);
                if (existingChannel) {
                    await this.createCallSystemMessage(existingChannel.id, CallSystemMessageType.CalledChannel);
                } else {
                    const channelService = this.injector.get(ChannelService);
                    const newChannel = await defaultChannelsContext.createTemporary([myUser().id, userId]);
                    const createdChannel = await defaultChannelsContext.ensureCreated(newChannel, null, channelService);
                    await this.createCallSystemMessage(createdChannel.id, CallSystemMessageType.CalledChannel);
                    channelService.selectChannel.next({ id: createdChannel.id });
                }
            }
        } catch (e) {
            if (e) console.error('Failed to start (and join) a call.', e);
            else {
                this.alertService.sendError(null, 'ERRORS.CALLS.USER_UNAVAILABLE');
            }
        }
    }

    // startDirectCall is used to start a call between this user and recipient via ACS.
    //
    // In web radio, we are using Teams call agent to make calls and as of v1.13.1 of
    // Azure communication calling library, it doesn't support more than one participant in
    // starting a call.
    async startDirectCall(channelId: string, type: CallType): Promise<TeamsCall | Call> {
        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;
        const channel = channelsContext.getDefault().getChannelById(channelId);
        await channelsContext.getDefault().ensureCreated(channel, null, this.injector.get(ChannelService));
        const recipientIds = await this.getChannelRecipientsIds(channelId);
        const participants: CommunicationIdentifierKind[] = recipientIds.map(rawId => createIdentifierFromRawId(rawId));
        participants.forEach(p => {
            if (!['communicationUser', 'microsoftTeamsUser'].includes(p.kind))
                throw new Error(`Cannot start a call with non ACS/Teams users.`);
        });
        if (type === CallType.Video && this.userAllowsVideo && !this.localVideoStream) this.localVideoStream = await this.createLocalVideoStream();
        const videoOptions = this.localVideoStream && type === CallType.Video ? { localVideoStreams: [this.localVideoStream] } : undefined;
        if (this.tokenType === 'teams') {
            if (participants.some(p => p.kind !== 'microsoftTeamsUser')) {
                // teams to acs call
                await this.requestTeamsToAcsCall(channelId);
                AnalyticsService.track(
                    this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
                    StAction.Requested,
                    this.constructor.name,
                    {
                        object: {
                            channelId,
                            channelMemberCount: recipientIds?.length + 1,
                        },
                    },
                );
                await this.createCallSystemMessage(channelId, CallSystemMessageType.CalledChannel);
                // TODO: https://weavix.atlassian.net/browse/WN-3583 - implement Teams -> ACS call
                this.alertService.sendError(null, 'Teams -> ACS Not implemented');
                this.alertService.setAppLoading(false);
                return;
            } else this.currentCall = (this.callAgent as TeamsCallAgent).startCall(participants[0] as MicrosoftTeamsUserIdentifier, { videoOptions });
        } else {
            // teams interop call
            this.currentCall = (this.callAgent as CallAgent).startCall(participants as CommunicationIdentifier[], { videoOptions });
        }
        this.setCurrentCalledChannelId(channelId);
        this.subscribeToEvents(this.currentCall);
        if (type === CallType.Video && this.allowVideoCalls) this.cameraStateChanged$.next({ isDisabled: true });
        this.callStarted$.next(this.currentCall);

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Started,
            this.constructor.name,
            {
                object: {
                    channelId,
                    callId: this.currentCall.id,
                    channelMemberCount: recipientIds?.length + 1,
                },
            },
        );
        return this.currentCall;
    }

    async endCall(forEveryone: boolean = false): Promise<void> {
        if (this.currentCall) {
            this.currentCall.hangUp( { forEveryone: forEveryone });
        } else {
            this.callEnded$.next({ code: 0, subCode: 0, resultCategories: null });
        }

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Ended,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall?.id,
                    callDuration: this.lastCallDuration,
                    video: this.localCameraState?.isDisabled === false ? 'on' : 'off',
                    audio: this.localMuteState?.isMuted === false ? 'on' : 'off',
                },
            },
        );
    }

    async createLocalVideoStream(): Promise<LocalVideoStream> {
        const selectedCamera = await LocalDeviceUtility.getDefaultVideoDevice();
        const cameras = await this.deviceManager.getCameras();
        let camera = cameras.find(x => x.id.replace('camera:', '') === selectedCamera.deviceId);
        if (!camera) {
            camera = cameras[0];
            LocalDeviceUtility.setDefaultVideoInput(camera.id.replace('camera:', ''));
        }
        if (camera) {
            return new LocalVideoStream(camera);
        }
        throw new Error('camera not available');
    }

    private async processStateChange(): Promise<void> {
        console.log(`Current Call ${this.currentCall.id} state changed: ${this.currentCall.state}`);
        switch (this.currentCall.state) {
            case 'Connected':
                this.callConnectedTimestamp = moment.now();
                this.callConnected$.next(this.currentCall);
                break;
            case 'Disconnected':
                console.log(`Current call ended with code: ${this.currentCall.callEndReason.code} and sub-code: ${this.currentCall.callEndReason.subCode}`);
                this.lastCallDuration = moment.now() - this.callConnectedTimestamp;
                this.callEnded$.next(this.currentCall.callEndReason);
                this.cleanUpCall();
                break;
            case 'Ringing':
                this.callRinging$.next();
        }
    }

    async refreshToken(): Promise<string> {
        return (await this.getAccessToken()).token;
    }

    private cleanUpCall(): void {
        this.unsubscribeToEvents(this.currentCall);
        this.currentCall = null;
        this.callConnectedTimestamp = 0;
        this.localCameraState = null;
        this.localMuteState = null;
        this.callType = null;
        this.localVideoStream = null;
    }

    private async incomingCall(call: TeamsIncomingCall | IncomingCall): Promise<void> {
        console.log(`Incoming call: id ${call.id} from "${call.callerInfo.displayName}`);
        if (this.currentCall) {
            await call.reject();
            return;
        }
        this.callIncoming$.next(call);
    }

    async acceptCall(call: TeamsIncomingCall | IncomingCall, type: CallType): Promise<void> {
        if (!this.allowVideoCalls && !this.allowVoiceCalls) {
            console.error('User not allowed to receive either type of calls. Rejecting this call.');
            return await this.rejectCall(call);
        }

        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;
        const videoOptions: VideoOptions = {};
        if (this.allowVideoCalls && this.userAllowsVideo && type === CallType.Video) {
            if (!this.localVideoStream) {
                this.localVideoStream = await this.createLocalVideoStream();
            }
            videoOptions.localVideoStreams = [this.localVideoStream];
        }
        this.currentCall = await call.accept({ videoOptions: videoOptions });
        this.subscribeToEvents(this.currentCall);
        this.callStarted$.next(this.currentCall);

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Joined,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall?.id,
                },
            },
        );
    }

    async rejectCall(call: TeamsIncomingCall | IncomingCall): Promise<void> {
        await call.reject();
        this.callEnded$.next(null);
        await this.createCallSystemMessage(this.currentCalledChannelId, CallSystemMessageType.DeclinedCall);
    }

    async muteMicrophone(): Promise<boolean> {
        if (!this.currentCall || this.currentCall.isMuted) return false;
        await this.currentCall.mute();
        if (this.currentCall.isMuted) this.muteStateChanged$.next({ isMuted: true });
        AnalyticsService.track(StObject.CallAudio, StAction.Toggled, this.constructor.name, { action: 'off' });
        return this.currentCall.isMuted;
    }

    async unmuteMicrophone(): Promise<boolean> {
        if (!this.currentCall || !this.currentCall.isMuted) return false;
        await this.currentCall.unmute();
        if (!this.currentCall.isMuted) this.muteStateChanged$.next({ isMuted: false });
        AnalyticsService.track(StObject.CallAudio, StAction.Toggled, this.constructor.name, { action: 'on' });
        return !this.currentCall.isMuted;
    }

    async turnOffCamera(): Promise<boolean> {
        if (!this.currentCall && !this.localVideoStream) return false;
        await this.currentCall.stopVideo(this.localVideoStream);
        this.localVideoStream = null;
        this.cameraStateChanged$.next({ isDisabled: true });
        AnalyticsService.track(StObject.CallCamera, StAction.Toggled, this.constructor.name, { action: 'off' });
        return true;
    }

    async turnOnCamera(): Promise<boolean> {
        if (!this.currentCall && this.localVideoStream) return false;
        this.localVideoStream = await this.createLocalVideoStream();
        await this.currentCall.startVideo(this.localVideoStream);
        this.cameraStateChanged$.next({ isDisabled: false });
        AnalyticsService.track(StObject.CallCamera, StAction.Toggled, this.constructor.name, { action: 'on' });
        return true;
    }

    async changeCamera(deviceId: string): Promise<boolean> {
        if (!this.currentCall || !this.localVideoStream) return false;
        const cameras = await this.deviceManager.getCameras();
        const camera = cameras.find(x => x.id.replace('camera:', '') === deviceId);
        if (camera) this.localVideoStream.switchSource(camera);
        return true;
    }

    async changeMicrophone(deviceId: string): Promise<boolean> {
        if (!this.deviceManager) return false;
        const microphones = await this.deviceManager.getMicrophones();
        const microphone = microphones.find(x => x.id.replace('microphone:', '') === deviceId);
        if (microphone) this.deviceManager.selectMicrophone(microphone);
        return true;
    }

    async changeSpeaker(deviceId: string): Promise<boolean> {
        if (!this.deviceManager) return false;
        const speakers = await this.deviceManager.getSpeakers();
        const speaker = speakers.find(x => x.id.replace('speaker:', '') === deviceId);
        if (speaker) this.deviceManager.selectSpeaker(speaker);
        return true;
    }

    async askPermissions(wantVideo: boolean): Promise<void> {
        if (!this.deviceManager) this.deviceManager = await this.callClient.getDeviceManager();
        this.userAllowsVideo = false;
        if (wantVideo) {
            const callResponse = await this.deviceManager.askDevicePermission({ audio: false, video: true });
            this.userAllowsVideo = callResponse.video;
        }
        await this.deviceManager.askDevicePermission({ audio: true, video: false });
    }

    async processMuteStateChange(): Promise<void> {
        console.log(`Call ${this.currentCall.id} is now ${this.currentCall.isMuted ? '' : 'un'}muted.`);
        this.muteStateChanged$.next({ isMuted: this.currentCall.isMuted });
    }

    async processRemoteParticipantsChange(): Promise<void> {
        this.remoteParticipantChanged$.next(this.currentCall.remoteParticipants as RemoteParticipant[]);
    }

    private subscribeToEvents(call: TeamsCall | Call): void {
        call.on('isMutedChanged', () => this.processMuteStateChange());
        call.on('stateChanged', () => this.processStateChange());
        call.on('remoteParticipantsUpdated', () => this.processRemoteParticipantsChange());
    }

    private unsubscribeToEvents(call: TeamsCall | Call): void {
        call.off('isMutedChanged', () => this.processMuteStateChange());
        call.off('stateChanged', () => this.processStateChange());
    }

    get hasLocalVideoStream(): boolean {
        return !!this.localVideoStream;
    }

    get isMuted(): boolean {
        return this.currentCall ? this.currentCall.isMuted : false;
    }

    getUserByCommunicationIdentifierKind(identifier: CommunicationIdentifierKind): MobxUser {
        const callerId = this.getIdFromAcsIdentifier(identifier);
        return callerId ? 
            identifier.kind === 'microsoftTeamsUser' ?
                usersContext.getDefault().getUserByTeamsId(callerId) : 
                usersContext.getDefault().getUserByAcsId(callerId)
                :
                null;
    }

    getIdFromAcsIdentifier(identifier: CommunicationIdentifierKind): string {
        switch (identifier.kind) {
            case 'communicationUser':
                return identifier.communicationUserId;

            case 'microsoftTeamsUser':
                return identifier.microsoftTeamsUserId;

            case 'phoneNumber':
                return identifier.phoneNumber;

            case 'unknown':
                return identifier.id;

            default:
                return null;
        }
    }

    getRawIdFromAcsIdentifier(identifier: CommunicationIdentifierKind): string {
        switch (identifier.kind) {
            case 'communicationUser':
                return identifier.communicationUserId;

            case 'microsoftTeamsUser':
                return identifier.rawId;

            case 'phoneNumber':
                return identifier.rawId;

            case 'unknown':
                return identifier.id;

            default:
                return null;
        }
    }

    logCall(call: TeamsCall | Call): void {
        console.log(`${call.direction} ${call.kind} with ID ${call.id} details:`);
        console.log(`This call is currently ${call.isMuted ? 'muted' : 'unmuted'} and in ${call.state} state.`);
        // eslint-disable-next-line max-len
        console.log(`Local participant has ${call.localAudioStreams.length} audio streams (${call.localAudioStreams.map(las => las.mediaStreamType).join()}) and ${call.localVideoStreams.length} video streams (${call.localVideoStreams.map(lvs => lvs.mediaStreamType).join()}).`);
        console.log(`Number of remote participants: ${call.remoteParticipants.length}`);
        call.remoteParticipants.forEach(rp => {
            const id = this.getRawIdFromAcsIdentifier(rp.identifier);
            console.log(`Remote participant ${id} (${rp.displayName}) has ${rp.videoStreams.length} video streams (${rp.videoStreams.map(x => x.mediaStreamType).join()}), and is ${rp.isMuted ? '' : 'not '}muted.`);
            const vs = rp.videoStreams.find(s => s.mediaStreamType === 'Video');
            if (vs) {
                console.log(`Remote participant ${id} 'Video' stream has a size of ${vs.size.width} by ${vs.size.height} and is ${vs.isAvailable ? '' : 'not '}available.`);
            } else {
                console.log(`Remote participant ${id} has no 'Video' streams.`);
            }
        });
    }

    async createMissedCallNotification(callId: string, callerId: string, channelId?: string, calleeId?: string) {
        try {
            if (channelId) await this.createCallSystemMessage(channelId, CallSystemMessageType.MissedCall, calleeId ?? null);
            await this.httpService.put(null, '/acs/missed-call-notification', { callId, callerId, calleeId: calleeId ?? null });
        } catch (e) {
            console.error('failed to create missed call notification', e);
        }
    }
}
