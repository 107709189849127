// todo: break this into separate files for each area of functionality
export enum MiscTopic {
    DEBUG = 'debug',

    RoipRadioReceive = 'roip/+/radio-receive/+',
    RoipRadioText = 'roip/+/radio-text/+',

    // user topics

    UserAccountAdded = 'user/+/account-added',
    UserNotification = 'user/+/notification',
    UserAudio = 'user/+/audio',
    UserStream = 'user/+/stream',
    UserAudioStream = 'user/+/audio-stream',
    UserBadgeUpdate = 'user/+/badge-update',

    // PTT topics

    AccountAudioStart = 'account/+/audio/+/start',
    AccountAudioOffline = 'account/+/audio/+/offline',
    AccountAudioSend = 'account/+/audio/+/send',
    AccountAudioReceive = 'account/+/audio/+/receive',
    UserAudioStart = 'user/+/audio/+/start',
    UserAudioOffline = 'user/+/audio/+/offline',
    UserAudioSend = 'user/+/audio/+/send',
    UserAudioReceive = 'user/+/audio/+/receive',
    PttTelemtry = 'user/+/ptt-telemtry',

    // Map-related topics (usually pretty high impact)

    AccountPersonBadgeUpdated = 'account/+/person/+/badge/updated',
    AccountPersonFacilitiesUpdated = 'account/+/person/+/facilities/updated',

    AccountFacilityItemLocationUpdated = 'account/+/facility/+/item/+/location-updated',

    AccountFacilityAlertPersonUpdated = 'account/+/facility/+/facility-alert/+/person-updated',
    AccountFacilityPersonBadgeUpdated = 'account/+/facility/+/person/+/badge/updated',
    AccountPersonNotification = 'account/+/person/+/notification',
    AccountPersonAudio = 'account/+/person/+/audio',
    AccountPersonBadgeDeregistered = 'account/+/person/+/badge/deregistered',
    AccountPersonBadgeEvent = 'account/+/person/+/badge/event',
    AccountPersonLogout = 'account/+/person/+/logout',
    AccountFacilityGeofenceEvent = 'account/+/facility/+/geofence/+/event',
    AccountFacilityCommunication = 'account/+/facility/+/communication',

    // Cache update topics
    AccountPersonPermissionsUpdated = 'account/+/person/+/permissions/updated',
    AccountFolderUpdated = 'account/+/folder/+/updated',
    AccountFacilityUpdated = 'account/+/facility/+/updated',
    AccountRuleUpdated = 'account/+/rule/+/updated',
    AccountGroupUpdated = 'account/+/group/+/updated',
    AccountGeofenceUpdated = 'account/+/geofence/+/updated',
    AccountSettingsUpdated = 'account/+/settings/updated',

    // Update topics

    AccountFeaturesUpdated = 'account/+/features/updated',
    AccountPersonUpdated = 'account/+/person/+/updated',
    AccountFacilityPersonUpdated = 'account/+/facility/+/person/+/updated',
    AccountPeopleUpdated = 'account/+/people-updated',
    AccountUsers = 'account/+/users',
    AccountPersonEventRequest = 'account/+/person/+/event/+/request',
    AccountPersonEvent = 'account/+/person/+/event',
    AccountPersonEventV2 = 'account/+/person/+/eventv2',
    AccountItems = 'account/+/items',
    AccountCraftUpdated = 'account/+/craft/+/updated',
    AccountCompanyUpdated = 'account/+/company/+/updated',
    AccountAlertTypeUpdated = 'account/+/alert-type/+/updated',
    AccountStatusUpdated = 'account/+/status/+/updated',
    AccountReportUpdated = 'account/+/report/+/updated',
    AccountReportTemplateUpdated = 'account/+/report-template/+/updated',
    AccountSafetyObservationUpdated = 'account/+/safety-observation/+/updated',

    UserNotificationCreated = 'user/+/notification-created',
    UserPersonConnection = 'user/+/person-connection',
    AccountFacilityPersonNotification = 'account/+/facility/+/person/+/notification',
    AccountFacilityPersonNotificationUpdated = 'account/+/facility/+/person/+/notification-updated',
    AccountPersonPersonConnection = 'account/+/person/+/person-connection',
    AccountPersonNotificationCreated = 'account/+/person/+/notification-created',
    AccountPersonNotificationUpdated = 'account/+/person/+/notification-updated',

    // Forms

    AccountFormSubmission = 'account/+/forms/+/submissions',

    // Dashboard data sources

    AccountDataSourceSql = 'account/+/data-source/+/sql/#',
    AccountDataSourceSimpleSql = 'account/+/data-source/+/simple-sql/#',
    AccountStaticSourceSql = 'account/+/static-source/+/sql/#',
    AccountFacilityAlertTypeUpdated = 'account/+/facility/+/alert-type-updated',

    // walt stuff

    WaltUpdated = 'walt/+/updated',
    AccountWaltUpdated = 'account/+/walt/updated',
    WaltLogout = 'walt/+/logout',
    WaltLogs = 'walt/+/logs',
    WaltSound = 'walt/+/sound', // plays "ding" to help locate a lost walt

    // Map things update topics (used by console -- could use sync instead)
    AccountMapGeofences = 'account/+/map/geofences',
    AccountMapFloorPlans = 'account/+/map/floor-plans',
    AccountMapFacilities = 'account/+/map/facilities',
    AccountMapBuildings = 'account/+/map/buildings',
    AccountMapStructures = 'account/+/map/structures',

    // Legacy query build (can remove)

    AccountDataSourceCollection = 'account/+/data-source/+/collection/#',
    AccountStaticSourceCollection = 'account/+/static-source/+/collection/#',

    // @unused (some are used by integration tests though)
    UserChannelMessageCreated = 'user/+/channel-message-created',
    UserChannelTypers = 'user/+/channel/+/typers',
    UserChannelUpdated = 'user/+/channel-updated/+',

    UserFacilityAlertCreated = 'user/+/facility-alert-created',
    UserSetting = 'user/+/setting/+',
    UserFakeLocation = 'user/+/fake-location',
    UserSendLogs = 'user/+/send-logs',

    AccountPersonChannelMessageCreated = 'account/+/person/+/channel-message/created',
    AccountPersonChannelUpdated = 'account/+/person/+/channel/+/updated',
    UserChannelFavoritesUpdated = 'user/+/channel-favorites/updated',
    AccountPersonFacilityAlertCreated = 'account/+/person/+/facility-alert/created',
    AccountPersonFaceUpdated = 'account/+/person/+/face/updated',

    AccountChannelTypers = 'account/+/channel/+/typers',
    AccountTagUpdated = 'account/+/tag/+/updated',

    AccountItemLocationUpdated = 'account/+/item/+/location-updated',
    AccountFacilityPersonStatusUpdated = 'account/+/facility/+/person/+/status/updated',
    AccountWispUpdated = 'account/+/wisp/updated',

    AccountPersonLocationUpdate = 'account/+/person/+/location-update',
    UserTelemetry = 'user/+/telemetry',
    SelfTelemetry = 'self/+/telemetry',
    SelfDownload = 'self/+/download/+',
    SelfUpload = 'self/+/upload',
}
